.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color:cornsilk;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 700px) {
  .navlink{
    display: block;
  }
}

nav{
  margin: 20px;
}

.navlink{
  padding: 30px;
  font-size: 40px;
}

@media only screen and (max-width: 1400px) {
  .navlink{
    font-size: 20px;
    padding: 15px;
  }
}

#header h1 {
  font-size: 60px;
}

#header img {
  max-width: 400px;
  border-radius: 10px;
  border-bottom: 5px solid gray;
  border-right: 5px solid gray;
}

.event{
  border: 4px solid black;
  border-radius: 5px;
  margin: 30px;
  width: 30%;
  padding: 10px;
  background-color:aliceblue;
}
@media only screen and (max-width: 1500px) {
  .event{
    width: 45%;
  }
}
@media only screen and (max-width: 900px) {
  .event{
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .book:nth-child(6){
    display: none;
  }
  .book:nth-child(5){
    display: none;
  }
  .book:nth-child(4){
    display: none;
  }
  .book:nth-child(3){
    display: none;
  }
}

@media only screen and (max-width: 1500px) {
  .book:nth-child(6){
    display: none;
  }
  .book:nth-child(5){
    display: none;
  }
}

.books{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.projects{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}


.booklink{
  margin: 15px;
  border: 4px solid black;
  border-radius: 5px;
  padding: 10px;
  background-color:aliceblue;
}

.booklink img{
  border-bottom: 4px solid grey;
  border-right: 4px solid grey;
  height: 400px;
}

.event p, .project p{
  text-align: left;
}


.event img {
  max-width: 100px;
  max-height: 100px;
}

.project {
  padding: 10px;
}

.text{
  border: 4px solid black;
  border-radius: 5px;
  background-color: white;
  margin: 50px;
  font-size: 20px;
}


.cloud{
  z-index: -1;
  position: fixed;
  max-width: 200px;
  opacity: 0.5;
  z-index: 1;
  animation: cloudAnimation 60s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes cloudAnimation {
  0% {
    left: 0;
  }
  100% {
    left: 85%;
  }
}

.hills{
  z-index: -1;
  position:fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  opacity: 0.5;
}

.barn{
  z-index: -1;
  position: fixed;
  bottom: 100px;
  left: 100px;
}

.sheep{
  z-index: -1;
  position: fixed;
  
  animation: sheepAnimation 60s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mailbutton{
  background-color: lime;
  padding: 10px;
  border-radius: 30px;
  border-bottom: 5px solid black;
  border-right: 5px solid black;
  font-size: 20px;
  font-weight: bold;
}

@keyframes sheepAnimation {
  0% {
    bottom: 0px;
    left: 0px;
  }
  20% {
    bottom: -200px;
    left: -100px;
  }
  30% {
    bottom: 0px;
    left: 200px;
  }
  50% {
    bottom: 100px;
    left: 30px;
  }
  100% {
    bottom: 0px;
    left: 0%;
  }
}

.book{
  max-width: 250px;
  max-height: 250px;
  margin: 5px;
}

.events{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.logos{
  height: 270px;
}
.book {
  opacity: 1;
  animation: fade 15s linear;
}


@keyframes fade {
0%, 5%, 65%, 100% { opacity: 0 }
20%, 60% { opacity: 1 }
}

.contact {
  width: 40px;
  height: 40px;
  margin: 10px;
}

.project{
  border: 4px solid black;
  border-radius: 4px;
  margin: 10px;
  background-color:aliceblue;
  width: 450px;
}
.project img{
  max-width: 300px;
  max-height: 300px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
